/* Font */

@font-face {
  font-family: 'averta_stdsemibold';
  src: url('./assets/Font/avertastd-semibold-webfont.woff2') format('woff2'),
    url('./assets/Font/avertastd-semibold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}


@font-face {
  font-family: 'averta_stdregular';
  src: url('./assets/Font/avertastd-regular-webfont.woff2') format('woff2'),
    url('./assets/Font/avertastd-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}


@font-face {
  font-family: 'averta_stdlight';
  src: url('./assets/Font/avertastd-light-webfont.woff2') format('woff2'),
    url('./assets/Font/avertastd-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'averta_stdblack';
  src: url('./assets/Font/avertastd-black-webfont.woff2') format('woff2'),
    url('./assets/Font/avertastd-black-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'averta_stdextrabold';
  src: url('./assets/Font/avertastd-extrabold-webfont.woff2') format('woff2'),
    url('./assets/Font/avertastd-extrabold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'averta_stdbold';
  src: url('./assets/Font/avertastd-bold-webfont.woff2') format('woff2'),
    url('./assets/Font/avertastd-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}



/* Customize UI */

:root {
  --amplify-colors-primary-10: #ccfef1;
  --amplify-colors-primary-20: #99fde3;
  --amplify-colors-primary-40: #66fdd5;
  --amplify-colors-primary-60: #32fcc7;
  --amplify-colors-primary-80: #19fcc0;
  --amplify-colors-primary-100: #00FCBA;
  --amplify-components-button-primary-hover-background-color: #00e2a7;
  --amplify-internal-button-border-color: #00e2a7;
  --amplify-components-button-primary-focus-background-color: #00e2a7;
  --amplify-components-authenticator-container-width-max: 600px;
}


html,
body,
#root {
  height: 100%;
  font-family: 'averta_stdregular', sans-serif;
}

#root {
  display: flex;
  justify-content: center;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'averta_stdregular', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #EFFFF8;
}

.amplify-image {
  overflow: unset;
  margin: 63px 0 81px 0;
}

[data-amplify-authenticator] [data-amplify-router] {
  background-color: transparent !important;
  border-width: 0 !important;
  box-shadow: unset !important;
  max-width: 380px;
  margin: 0 auto;
}

.amplify-button--small {
  font-size: 18px;
}

.amplify-input,
.amplify-select {
  background-color: #00FFA8;
  border-radius: 20px;
  border: unset;
}

.amplify-select:focus,
.amplify-input.amplify-field-group__control:focus,
button.amplify-button.amplify-field-group__control.amplify-field__show-password:focus {
  box-shadow: unset;
}

button.amplify-button.amplify-field-group__control.amplify-field__show-password {
  background-color: #00FFA8;
  border-radius: 0 20px 20px 0;
  border: unset;
}

.amplify-flex.amplify-field.amplify-textfield>.amplify-label,
.amplify-flex.amplify-field.amplify-selectfield>.amplify-label {
  display: none;
}

::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #0B0C0C;
}

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #0B0C0C;
  opacity: 1;
}

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #0B0C0C;
  opacity: 1;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #0B0C0C;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #0B0C0C;
}

::placeholder {
  /* Most modern browsers support this now. */
  color: #0B0C0C;
}

span.amplify-icon>svg>path {
  fill: #0B0C0C;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #00FFA8 inset !important;
}

.amplify-button--primary {
  background-color: #0E120E;
  border-radius: 20px;
  border: unset;
  color: #00FFA8;
  display: inline-block;
  width: auto;
  width: min-content;
  padding: 8px 70px;
  align-self: flex-end;
  position: relative;
  white-space: nowrap;
}

.amplify-button--primary::after {
  content: "";
  background-image: url('./assets/chevron.svg');
  position: absolute;
  right: 20%;
  top: 50%;
  transform: translateY(-50%);
  font-size: 12px;
  pointer-events: none;
  width: 8px;
  height: 14px;
  background-repeat: no-repeat;
}

.amplify-button--link {
  color: #00E899;
  position: relative;
}

.amplify-button--link:after {
  content: '';
  position: absolute;
  width: 150px;
  height: 1px;
  background: #00E899;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.amplify-alert--error .amplify-button--link:after {
  height: 0 !important;
}

.amplify-button--link:hover {
  color: #00E899;
  background-color: transparent;
}

.amplify-button.amplify-field-group__control.amplify-button--link.amplify-button--small {
  width: fit-content;
  margin: 0 auto;
  margin-top: 64px;
}

.amplify-alert--error {
  border-radius: 20px;
}

.amplify-button--link:focus {
  background-color: transparent;
  box-shadow: unset;
}

.amplify-tabs__item:not(.amplify-tabs__item--active) {
  border-color: #10120E;
  color: #10120E;
}

.amplify-tabs__item--active:hover {
  color: #19fcc0;
}

@media screen and (max-width: 600px) {
  #logo-effe {
    display: none;
  }

  .lottie-container {
    margin-top: 90px;
  }

  [data-amplify-authenticator] [data-amplify-router] {
    max-width: 350px;
  }

  .amplify-button.amplify-field-group__control.amplify-button--link.amplify-button--small {
    margin-top: 36px;
  }
}
/* src/components/Loader.css */

.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* Assicura che il loader sia centrato verticalmente */
}

.spinner {
    animation: rotate 2s linear infinite;
}

.path {
    stroke: #00E899;
    stroke-dasharray: 150, 200;
    stroke-dashoffset: -10;
    animation: dash 1.5s ease-in-out infinite;
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }

    50% {
        stroke-dasharray: 150, 200;
        stroke-dashoffset: -40;
    }

    100% {
        stroke-dasharray: 150, 200;
        stroke-dashoffset: -120;
    }
}